<template>
    <div>

        <div class="col-md-12" v-if="viewno==0">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="page-header" style="margin-bottom: 0px;">
                        <i class="fa fa fa-calculator animated bounceInDown show-info"></i>
                        <span>Property List</span>
                    </h3>
                </div>
            </div>
            <div class="col-md-12 table-responsive" style="padding:0px;">
                <div class="panel">
                    <div class="panel-body table" style="overflow-x:auto;padding-left:0px">
                        <table class="table table-bordered table-font"
                            style="margin-bottom:0;font-size:15px!important;">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Street & Pincode</th>
                                    <th>Landmark, City & State</th>
                                    <th>Youtube Link</th>
                                    <th>Contact Person</th>
                                    <th>Contact No.</th>
                                    <th>Price</th>
                                    <th>Size in (Guz)</th>
                                    <th>Front x Length</th>
                                    <th>Constructed</th>
                                    <th>KDA Approved</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(item, index) in properties" :key="item.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.title ? item.title : 'N/A' }}</td>
                                    <td>
                                        <span v-if="item.street != null">{{ item.street }}</span>
                                        <span v-if="item.street != null && item.pincode != null">, </span>
                                        <span v-if="item.pincode != null">{{ item.pincode }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item.landmark != null">{{ item.landmark }}</span>
                                        <span v-if="item.landmark != null && item.city != null">, </span>
                                        <span v-if="item.city != null">{{ item.city }}</span>
                                        <span v-if="item.city != null && item.state > 0">, </span>
                                        <span v-if="item.state">{{ getMasterLabelByType(item.state,MASTER_STATES) }}</span>

                                    </td>
                                    <td>
                                        <div v-if="item.youtubelink.length > 0">
                                            <div v-for="(link,index) in item.youtubelink" :key="link.id" >
                                                <a :href="link" target="_blank">{{ link }}</a>
                                                <span v-if="(item.youtubelink.length - index) > 1">,</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ item.contactpersonname ? item.contactpersonname : 'N/A' }}</td>
                                    <td>
                                        <div v-if="item.contact_no?.length > 0">
                                            <div v-for="(contact,index) in item.contact_no" :key="contact.id" >
                                                <span>{{ contact }}</span>
                                                <span v-if="(item.contact_no.length - index) > 1">,</span>
                                            </div>
                                          
                                        </div>
                                    </td>
                                    <td>{{ item.price ? item.price : '0.00' }}</td>
                                    <td>{{ item.sizeinguz ? item.sizeinguz : '0.00' }}</td>
                                    <td>{{ item.frontinfoot ? item.frontinfoot : '0.00' }} * {{ item.lengthinfoot ? item.lengthinfoot : '0.00' }}</td>
                                    <td>{{ item.constructed==1 ? 'Yes' : 'NO' }}</td>
                                    <td>{{ item.iskda==1 ? 'Yes' : 'NO' }}</td>
                                    <td>{{ item.description ?  item.description : 'N/A' }}</td>
                                    <td>
                                        <button class="btn btn-primary-edit" @click="edit(item)"><i class="fa fa-edit"></i></button>
                                        <button class="btn btn-primary-print" @click="uploadImg(item)"><i class="fa fa-upload"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-md-12" style="margin-top: 20px">
                <nav aria-label="Page navigation example" style="text-align: right !important">
                    <paginate  style="margin: 0px !important; float: right"
                        :page-count="noofpages" :click-handler="clickCallback" :prev-text="'&laquo;'"
                        :next-text="'&raquo;'" :container-class="'pagination'">
                    </paginate>
                </nav>
            </div>
        </div>
        <div v-if="viewno==1">
            <property-form :editproperty="editproperty" @close="this.cancel"></property-form>
        </div>
        <div v-if="viewno==2">
            <upload-images :editproperty="editproperty"  @close="this.cancel"></upload-images>
        </div>
    </div>
</template>
<script>
import Paginate from "vuejs-paginate";
import { mapGetters } from 'vuex'
import axios from 'axios'
import PropertyForm from "./PropertyForm.vue";
import Constants from "../../components/utilities/Constants.vue";
import UploadImages from "../upload/UploadImages.vue";

export default {
    mixins: [Constants],
    components: { Paginate,PropertyForm,UploadImages },
    data() {
        return {
            currentpage: 1,
            noofrec: 10,
            count: 0,
            properties: [],
            viewno :0,
            editproperty:null



        }
    },
    computed: {
        ...mapGetters([

        ]),
        noofpages() {
            return Math.ceil(parseInt(this.count) / parseInt(this.noofrec))
        }

    },
    mounted() {
        this.refresh()
    },
    methods: {
        clickCallback (currentpage) {
            this.currentpage=currentpage
            this.refresh();
        },
        refresh() {
            let param = { noofrec: this.noofrec, currentpage: this.currentpage }
            axios.post('api/realstate/list', param)
                .then(response => {
                    this.count = response.data.total
                    this.properties = response.data.properties
                })
                .catch(error => {
                    console.log(error)
                })
        },
        edit(item) {
            this.viewno = 1
            this.editproperty = item
        },
        cancel(){
            this.viewno = 0
            this.editproperty = null
            this.refresh()
        },
        uploadImg(item){
            this.viewno = 2
            this.editproperty = item
        }


    },
}
</script>
