<template>
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-6">
                <h3 class="page-header" style="margin-bottom: 0px;">
                    <i class="fa fa-picture-o animated bounceInDown show-info"></i>
                    <span> Upload Images</span>
                </h3>
            </div>
            <div class="col-md-6">
                <div class="pull-right">
                    <button type="button" class="btn btn-primary btn-sm mt-20" @click="backBtn()">Back</button>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-12">
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4"></div>
                <div class="col-md-4"></div>
            </div>
        </div>  -->
        <div class="image-uploader">

            <!-- <div class="upload-box" @click="openFileDialog">
                <span><i class="fa fa-cloud-upload" aria-hidden="true" style="font-size: 60px;"></i></span>
            </div> -->
            <input type="file" ref="fileInput" @change="handleImageUpload" accept="image/*" hidden />
            <div class="upload-box" @click="openFileDialog">
                <span>Click here to upload images</span>
            </div>

            <div class="image-container">
                <div v-for="(image, index) in uploadedImages" :key="index" class="image-wrapper">
                    <button class="remove-btn" @click="removeImage(image, index)">✖</button>
                    <img :src="image?.images?.downloadurl" class="uploaded-image" />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import UploadMixin2 from "../../components/utilities/UploadMixin2.vue"
export default {
    mixins: [UploadMixin2],
    props: ['editproperty'],
    data() {
        return {
            uploadedImages: []
        };
    },
    computed: {
        ...mapGetters(['uploadedimage']),
    },
    mounted() {
        if (this.editproperty != null) {
            this.refresh()
        }
    },
    methods: {
        openFileDialog() {
            this.$refs.fileInput.click();
        },
        refresh() {
            let param = { id: this.editproperty.id }
            axios.post('api/realstate/document/fetch', param)
                .then((response) => {
                    this.uploadedImages = response.data.document.documents

                })
        },
        handleImageUpload(event) {
            let obj = { 'type': 0, 'directorypath': '', 'counter': 0, 'typeid': 0, 'typeint': 18, 'width': 1000, 'height': 1000 };
            this.$store.commit('assignfilecomponents', obj);
            let fieldjson = { 'realstatepropertyid': this.editproperty.id, 'title': this.editproperty.title }
            this.$store.commit('assignfieldjson', fieldjson);
            this.processFile2(event);
        },
        removeImage(item, index) {
            if (confirm('Are you sure you want to remove')) {
                let param = { documentid: item.id, amazonid: item.amazonid }
                axios.post('api/realstate/document/disable', param)
                    .then((response) => {
                        if (response.data.status) {
                            this.uploadedImages.splice(index, 1);
                        }
                    }).catch((error) => {console.log(error)})
            }
        },
        uploaded() {
            if (this.uploadedimage.id > 0) {
                this.uploadedImages.push(this.uploadedimage)
            }
        },
        backBtn() {
            this.$emit('close')
        }
    }
};
</script>

<style scoped>
.image-uploader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.image-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 30px;
}

.image-wrapper {
    position: relative;
    display: inline-block;
}

.uploaded-image {
    width: 200px;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
}

.upload-box {
    width: 220px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ccc;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    margin-top: 20px;
}

.remove-btn {
    position: absolute;
    top: -3px;
    left: -5px;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 12px;
}

.uploaded-images-section {
    margin-top: 20px;
    text-align: center;
}
</style>